import Footer from "../components/Footer";
import Header from "../components/Header";
import { useState, useEffect, useRef } from "react";
import useAuth from "../hooks/useAuth";

function Reviews() {
  const [submit, setSubmit] = useState("Submit");
  const [superReview, setSuperReview] = useState("");
  const [reviews, setReviews] = useState([]);

  async function getReviews(event) {
    event.preventDefault();
    event.target.elements.submitBtn.disabled = true;
    setSubmit(
      <>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </>
    );
    let apartmentName = event.target[0].value;

    try {
      const response = await fetch("https://smartrentics-back.onrender.com/places/reviews/"+apartmentName);

      const data = await response.json();
      console.log(data.reviews);
      setReviews(data.reviews);
      setSuperReview(await getSuperReview(data));
      event.target.elements.submitBtn.disabled = false;
      setSubmit("Submit");        
       
    } catch (err) {
      event.target.elements.submitBtn.disabled = false;
      setSubmit("Submit");
      console.error(err);
    }
  }

  async function getSuperReview(reviews) {
    try{

      const response = await fetch(
        "https://smartrentics-py.azurewebsites.net/analyze-reviews",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reviews),
        }
      );
      const resData = await response.json();
      console.log(resData.comprehensive_review);
      return resData.comprehensive_review;

    }catch (err) {
      console.error(err);
    }
  }


  return (
    <>
      <Header />
      <div className="container-fluid m-0">
        <div className="container-fluid m-0">
          <div className="row">
            <div className="col-md-2 m-0 border-end">
              <br />
              <br />
              <br />
            </div>
            <div className="col-md-10 p-0 m-0 pr-2">
              <div className="review-header col-md-11 mt-5 py-5 px-3">
                <h2 className="py-3 ps-5">Reviews</h2>                
                    <form
                    className=""
                    action=""
                    onSubmit={(event) => getReviews(event)}
                  >
                    <div className="row px-5">
                      <div className="col"></div>
                      <div className="col-md-5">
                      <label className="text-blue" htmlFor="cityOne">
                        <h5 className="fw-light py-1">Search Reviews</h5>
                      </label>
                        <input required type="text" className="form-control form-control-sm" name="apartmentName" placeholder="Apartment Name" aria-label="Apartment Name/Address" />
                      </div>
                      <div className="col mt-auto">
                        {/* <input type="submit" className="btn btn-danger rounded-pill px-4" value="Submit"/> */}
                        <button
                          type="submit"
                          name="submitBtn"
                          className="btn btn-danger btn-sm rounded-pill px-4"
                        >
                          {submit}
                        </button>
                      </div>
                    </div>
                  </form>

                { superReview === "" ?(
                  <div className="py-5 text-center">
                    <br /><br />
                    <h1 className="text-blue fw-light">Get Quick reviews</h1>
                    <p>Search reviews of an apartment complex.</p>
                    <br /><br />
                  </div>
                ):(
                  <>
                    <div className="super-reviews p-5 border-bottom ">
                      <div className="row super p-5 bg-blue text-white">
                        <div className="col-md-3 border-end">
                          <h4 className="fw-light text-red">AI Generated</h4>
                          Super Review
                        </div>
                        <div className="col-md-9 px-5">
                            <p className="super-review ">
                              {superReview}
                            </p>
                        </div>
                      </div>
                    </div>
                    <div className="basic-reviews">
                  { reviews.length > 0 ? (
                    reviews.map((review, index) => (
                      <div className="row py-5  border-bottom" key={index}>
                        <div className="col-md-3 border-end p-5">
                          <h4 className="fw-light">{review.author_name}</h4>
                          <br />
                          Rating:
                          <div className="progress" style={{height: "8px"}}>
                            <div className="progress-bar bg-warning" role="progressbar" style={{width: `${review.rating*20}%`, height: "8px"}} aria-valuenow="8" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <br />
                          {review.relative_time_description}
                        </div>
                        <div className="col-md-9 p-5">
                            <p className="review">
                              {review.text}
                            </p>
                        </div>
                      </div>
                    ))
                  ): (
                    <></>
                  )}
                </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Reviews;